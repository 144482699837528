import { createSlice } from "@reduxjs/toolkit";
import { getInferenceItemStatus } from "../../../components/Common/helpers";

// import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";

const initialState = {
  customersData: {},
  selectedCustomer: {},
  selectedDistributionCenter: {},
  loadingPalletsData: false,
  palletsData: {},
  routesData:{},
  wrappersData:{},
  operatorsData:{},
  loadingPalletsDetails: false,
  palletInferenceID: "",
  palletDetails: {},
  goBackToPalletReview: false,
  pageSubTitle: "",
  isStartRefresh: false,
  isStopScanPallet:false,
  vorReferenceNumber: "",
};

export const PalletReviewReducer = createSlice({
  name: "palletReview",
  initialState,
  reducers: {
    setCustomers: (state, action) => {
      state.customersData = action.payload;
    },
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },
    setSelectedDistributionCenter: (state, action) => {
      state.selectedDistributionCenter = action.payload;
    },
    setLoadingPalletsData: (state, action) => {
      state.loadingPalletsData = action.payload;
    },
    setPalletsData: (state, action) => {
      state.palletsData = action.payload;
    },
    clearPalletsData: (state, action) => {
      state.palletsData = [];
    },

    setRoutesData: (state, action) => {
      state.routesData = action.payload;
    },

    setWrapperData: (state, action) => {
      state.wrappersData = action.payload;
    },
    setOperatorsData: (state, action) => {
      state.operatorsData = action.payload;
    },


    setLoadingPalletsDetails: (state, action) => {
      state.loadingPalletsDetails = action.payload;
    },
    setPalletInferenceID: (state, action) => {
      state.palletInferenceID = action.payload;
    },
    setPalletDetails: (state, action) => {
      if (action.payload.inference_result !== undefined) {
        let sortedResult = action.payload.inference_result.sort((a, b) => {
          const aStatus = getInferenceItemStatus(a),
            bStatus = getInferenceItemStatus(b);
          if (aStatus === bStatus) return 0;

          const statusOrder = [
            "Audited",
            "Extra",
            "Missing",
            "Untrained",
            "Can’t confirm",
            "No error",
          ];

          const aOrder = statusOrder.indexOf(aStatus),
            bOrder = statusOrder.indexOf(bStatus);
          console.log(aOrder, bOrder)
          if (aOrder === -1 || bOrder === -1) return 0;

           return aOrder > bOrder ? 1 : -1;

        })
        action.payload.inference_result = sortedResult
      }
      state.palletDetails = action.payload;

    },
    setGoBackToPalletReview: (state, action) => {
      state.goBackToPalletReview = action.payload;
    },
    setPageSubTitle: (state, action) => {
      state.pageSubTitle = action.payload;
    },
    setPageChildTitle: (state, action) => {
      state.pageChildTitle = action.payload;
    },
    setStartRefresh: (state, action) => {
      state.isStartRefresh = action.payload;
    },
    setStopScanPallet: (state, action) => {
      state.isStopScanPallet = action.payload;
    },
    setVorReferenceNumber: (state, action) => {
      state.vorReferenceNumber = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function.
export const {
  setCustomers,
  setSelectedCustomer,
  setSelectedDistributionCenter,
  setLoadingPalletsData,
  setPalletsData,
    clearPalletsData,
  setRoutesData,
    setWrapperData,
  setOperatorsData,
  setLoadingPalletsDetails,
  setPalletInferenceID,
  setPalletDetails,
  setGoBackToPalletReview,
  setPageSubTitle,
  setPageChildTitle,
  setStartRefresh,
  setStopScanPallet,
  setVorReferenceNumber,
} = PalletReviewReducer.actions;

// export const selectCustomersData = (state) => state.palletReview.customersData;

export default PalletReviewReducer.reducer;
