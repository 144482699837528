export class PalletData {

    static palletImages = [];
    static selectedRoutes = "";
    static selectedOperators = "";
    static selectedWrappers = "";

    static selectedRouteValues = [];
    static selectedOperatorValues = [];
    static selectedWrapperValues = [];

    static isRouteSelected = false
    static isOpratorSelected = false;
    static isWrapperSelected = false;

    static isPalletImageExist = (picklistId,imgUrl)=>{
      // if(PalletData.palletImages.indexOf())
        let arr = PalletData.palletImages;
        if(arr.length>0){
            let obj = arr.find(o => o.Key === picklistId);
            if(obj){
                return obj.Value;
            }
        }
        PalletData.palletImages.push({Key:picklistId,Value:imgUrl});
        return imgUrl;

    }

}
